var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "align-center": "",
        "fill-height": "",
        "gap-xs-1": "",
        "gap-md-2": !_vm.dense
      }
    },
    _vm._l(_vm.steps, function(step) {
      return _c(
        "v-flex",
        { key: step.step, attrs: { shrink: "" } },
        [_c("step-icon", { attrs: { step: step } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }