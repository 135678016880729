export enum HomeStatusEnum {
  /* eslint-disable no-unused-vars */
  INITIAL = 'INITIAL',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  RECOMMENDED = 'RECOMMENDED'
  /* eslint-enable no-unused-vars */
}

export enum HomeStepTypeEnum {
  /* eslint-disable no-unused-vars */
  CHECKUP = 'CHECKUP',
  EXERCISE = 'EXERCISE',
  SESSION = 'SESSION',
  ROTATION = 'ROTATION',
  INTRODUCTION = 'INTRODUCTION',
  /* eslint-enable no-unused-vars */
}

export interface IHomeDailyProgressStep {
  id: number,
  order: number,
  type: HomeStepTypeEnum,
  completed: boolean,
  lastCompleted: boolean,
  recommended: boolean,
  isLast?: boolean,
  content?: string,
  completedUen?: string,
}

export interface IHomeDailyProgress {
  steps: IHomeDailyProgressStep[];
  introduction: boolean,
  firstLogin: boolean,
  firstCheckup: boolean,
  checkupRemainingDays: number,
  checkupTotalDays: number,
  checkupLastFrequency: number,
}

export interface IHomeProductSession {
  id: string,
  uen: string,
  order: number,
  status: HomeStatusEnum,
}

export interface IHomeProduct {
  id: string,
  title: string,
  subtitle?: string,
  image?: string,
  uen: string,
  status: HomeStatusEnum,
  order: number,
  sessions?: Array<IHomeProductSession>,
}

export interface IHome {
  products: Array<IHomeProduct>,
}

export interface ITherapies {
  firstCheckupDue?: boolean,
  recommended: Array<IHomeProduct>,
  discover: Array<IHomeProduct>,
  completed: Array<IHomeProduct>,
}

export interface IHomeBattery {
  value: number,
}

export interface IHomeGauge {
  value: number,
  min: number,
  max: number,
}

export interface IHomeCheckup {
  remainingDays: number,
  totalDays: number,
}

export interface IHomeResilience {
  value: number,
  min: number,
  max: number,
}
export interface ICallout {
  identifier: string,
  handle: string,
  title?: string,
  content?: string,
  target: string,
}

export interface HomeState {
  productHome: IHome,
  dailyProgress?: IHomeDailyProgress,
  therapies: ITherapies,
  callout?: ICallout,
  popoverConfig?: {
    key: string,
    identifiers: string[],
  },
}
