var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "step-icon step-number",
        class: [
          _vm.step.status.toLowerCase(),
          {
            large: _vm.large,
            mobile: _vm.$vuetify.breakpoint.smAndDown
          }
        ]
      },
      [_vm._v("\n    " + _vm._s(_vm.step.text) + "\n  ")]
    ),
    _vm.step.status === _vm.status.COMPLETED
      ? _c(
          "div",
          {
            staticClass: "step-icon step-completed",
            class: {
              large: _vm.large,
              mobile: _vm.$vuetify.breakpoint.smAndDown
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "checkmark",
                class: { animation: _vm.step.animation },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 40 40"
                }
              },
              [
                _c("circle", {
                  staticClass: "checkmark-circle",
                  attrs: { cx: "20", cy: "20", r: "20", fill: "none" }
                }),
                _c("path", {
                  staticClass: "checkmark-check",
                  attrs: {
                    fill: "none",
                    stroke: "#fff",
                    "stroke-linecap": "round",
                    "stroke-width": "2.5",
                    d: "m12 22.397 6.119 5.354m-.004-.004L27.036 12"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }