








import Vue from 'vue';
import StepIcon, { IStep } from '@/views/components/steppers/step-icon.vue';

export interface IHorizontalStep extends IStep {
}

export default Vue.extend({
  name: 'horizontal-steps',
  components: {
    StepIcon,
  },
  props: {
    steps: Array as () => Array<IStep>,
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
